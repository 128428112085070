<template>
  <div>
    <!-- Breadcrumb Component -->
    <breadcrumb-area :breadcrumb="breadcrumbData"/>
    <!-- Service Details Component -->
    <service-details-area :ServiceDetails="ServiceDetails"/>
  </div>
</template>
  
<script>
import BreadcrumbArea from "../components/BreadcrumbArea";
import ServiceDetailsArea from "../components/ServiceDetailsArea";
import {mapActions, mapState} from "vuex";
export default {
  name: "PracticeServiceDetails",
  components: {ServiceDetailsArea, BreadcrumbArea},
  // props: {
  //   item: {
  //     type: Object,
  //     required: true
  //   }
  // },
  data() {
    return {
      // Item: this.$route.params.item,
      // breadcrumb: {
      //   page: this.$route.params.item.name,
      //   pageTitle: 'Service Details',
      //   sequence:[
      //     {
      //       page: "Practices",
      //       pageTitle: "Practices",
      //       pagePath: "/services",
      //     },
      //     {
      //       page: this.$route.params.item.type,
      //       pageTitle: this.$route.params.item.type,
      //       pagePath: "/services/"+this.$route.params.item.type,
      //     },
      //     {
      //       page: this.$route.params.item.name,
      //       pageTitle: "Service Details",
      //     }
      //   ]
      // },
      // ServiceDetails: {
      //   title: this.$route.params.item.name,
      //   description: 'TEST DESCRIPTION',
      //   about: 'TEST SERVICE ABOUT'
      // }
    }
  },
  mounted() {
    debugger
    this.load(this.$route.params.idPractice, this.$route.params.idService)
  },
  computed: {
    ...mapState(['practice', 'service']),
    breadcrumbData(){
      return {
        page: this.service.name,
        pageTitle: 'Service Details',
        pagePath: '',
        sequence:[
          {
            page: "Practices",
            pageTitle: "Practices",
            pagePath: "/services",
          },
          {
            page: this.practice.name,
            pageTitle: this.practice.name,
            pagePath: "/services/" + this.service.type
          },
          {
            page: this.service.name,
            pageTitle: this.service.name,
          }
        ]
      }
    },
    ServiceDetails(){
      return {
        title: this.service.name,
        description: 'TEST DESCRIPTION',
        about: 'TEST SERVICE ABOUT',
        dataPath: this.service.data
      }
    }
  },
  methods:{
    ...mapActions(['loadService', 'loadPractice']),
    load(practiceName, serviceName){
      debugger
      this.loadService(serviceName)
      this.loadPractice(practiceName)
    }
  }
}
</script>
  
<style scoped>

</style>